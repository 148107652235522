import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';

/**
 * Component for managing observations in promotional pages
 *
 * @param {Object} props - Component props
 * @param {Array<string>} props.observations - List of observation texts
 * @param {Function} props.onAddObservation - Callback when adding a new observation
 * @param {Function} props.onRemoveObservation - Callback when removing an observation
 * @param {Function} props.onObservationChange - Callback when changing an observation's text
 */
const ObservationsList = ({
  observations,
  onAddObservation,
  onRemoveObservation,
  onObservationChange
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Typography variant="h6" sx={{ mt: 2 }}>Observações</Typography>
      {observations.map((observation, index) => (
        <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <TextField
            fullWidth
            label={`Observação ${index + 1}`}
            multiline
            rows={2}
            value={observation}
            onChange={(e) => onObservationChange(index, e.target.value)}
          />
          <IconButton 
            onClick={() => onRemoveObservation(index)}
            disabled={observations.length <= 1}
            color="error"
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ))}
      
      <Button 
        variant="outlined" 
        onClick={onAddObservation}
        startIcon={<Typography>+</Typography>}
      >
        Adicionar Observação
      </Button>
    </Box>
  );
};

export default ObservationsList;
