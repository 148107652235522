import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Divider,
  Paper,
  useTheme, 
  useMediaQuery,
  List,
  ListItem,
  ListItemText,
  Fade,
  Chip,
  Card,
  CardContent
} from '@mui/material';
import { 
  PieChart, 
  Pie, 
  Cell, 
  Tooltip, 
  ResponsiveContainer, 
  Legend,
  Sector,
  AnimationTiming
} from 'recharts';

// Enhanced color palette
const COLORS = [
  '#4285F4', '#34A853', '#FBBC05', '#EA4335', 
  '#8884d8', '#00C49F', '#FF8042', '#0088FE', 
  '#FF5C8D', '#A28DFF', '#64C2A6', '#FF6B6B'
];

// Gradient definitions for enhanced visuals
const renderGradients = () => {
  return COLORS.map((color, index) => {
    const lighterColor = color + '99'; // Adding transparency for lighter shade
    return (
      <linearGradient key={`gradient-${index}`} id={`colorGradient${index}`} x1="0" y1="0" x2="0" y2="1">
        <stop offset="5%" stopColor={color} stopOpacity={0.9}/>
        <stop offset="95%" stopColor={lighterColor} stopOpacity={0.8}/>
      </linearGradient>
    );
  });
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  if (percent < 0.05) return null;
  
  const radius = innerRadius + (outerRadius - innerRadius) * 0.6;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text 
      x={x} 
      y={y} 
      fill="white" 
      textAnchor={x > cx ? 'start' : 'end'} 
      dominantBaseline="central"
      style={{ fontWeight: 'bold', fontSize: '12px', textShadow: '1px 1px 1px rgba(0,0,0,0.5)' }}
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

// Active shape for hover effect
const renderActiveShape = (props) => {
  const {
    cx, cy, innerRadius, outerRadius, startAngle, endAngle,
    fill, payload, percent, value
  } = props;

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius + 6}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
        stroke="#fff"
        strokeWidth={2}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
    </g>
  );
};

const CategoryPieChart = ({ categoryData = [], title = "Performance de Categorias", height = 350 }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeIndex, setActiveIndex] = useState(null);
  
  // Filter out categories with zero products
  const filteredData = categoryData.filter(item => item.value > 0);
  const totalValue = filteredData.reduce((sum, item) => sum + item.value, 0) || 0;
  
  // Handle empty state with better visuals
  if (!filteredData || filteredData.length === 0) {
    return (
      <Card elevation={3} sx={{ 
        height: '100%', 
        background: `linear-gradient(to bottom right, ${theme.palette.background.paper}, ${theme.palette.background.default})`,
        borderRadius: 2
      }}>
        <CardContent>
          <Typography variant="h6" gutterBottom fontWeight="medium" color="primary">
            {title}
          </Typography>
          <Divider sx={{ mb: 3 }} />
          <Fade in={true} timeout={1000}>
            <Box 
              display="flex" 
              flexDirection="column" 
              justifyContent="center" 
              alignItems="center" 
              height={height - 80} 
              sx={{ opacity: 0.7 }}
            >
              <Box
                component="img"
                src="/chart-placeholder.png" 
                alt="No data"
                sx={{ 
                  width: 100, 
                  height: 100, 
                  mb: 2,
                  opacity: 0.6
                }}
              />
              <Typography color="textSecondary" variant="body1">
               Nenhuma informação disponível
              </Typography>
              <Typography color="textSecondary" variant="caption" sx={{ mt: 1 }}>
                Adicione produtos para visualizar o desempenho das categorias
              </Typography>
            </Box>
          </Fade>
        </CardContent>
      </Card>
    );
  }

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const onPieLeave = () => {
    setActiveIndex(null);
  };

  return (
    <Card elevation={3} sx={{ 
      height: '100%', 
      background: `linear-gradient(to bottom right, ${theme.palette.background.paper}, ${theme.palette.background.default})`,
      borderRadius: 2,
      transition: 'all 0.3s ease',
      '&:hover': {
        boxShadow: theme.shadows[8],
        transform: 'translateY(-2px)'
      }
    }}>
      <CardContent sx={{ height: '100%', p: 2 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
          <Typography variant="h6" fontWeight="medium" color="primary">
            {title}
          </Typography>
          <Chip 
            label={`${totalValue} produtos ativos`}
            size="small"
            color="secondary"
            sx={{ fontWeight: 'bold' }}
          />
        </Box>
        <Divider sx={{ mb: 2 }} />
        <Box height={height} display="flex" flexDirection="column" sx={{ position: 'relative' }}>
          <Box sx={{ width: '100%', height: '100%', position: 'relative' }}>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <defs>
                  {renderGradients()}
                </defs>
                <Pie
                  activeIndex={activeIndex}
                  activeShape={renderActiveShape}
                  data={filteredData}
                  cx="50%"
                  cy="45%"
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={isMobile ? 80 : 110}
                  innerRadius={isMobile ? 40 : 60}
                  fill="#8884d8"
                  dataKey="value"
                  onMouseEnter={onPieEnter}
                  onMouseLeave={onPieLeave}
                  animationDuration={1200}
                  animationBegin={200}
                  animationEasing="ease-out"
                >
                  {filteredData.map((entry, index) => (
                    <Cell 
                      key={`cell-${index}`} 
                      fill={entry.color || `url(#colorGradient${index % COLORS.length})`} 
                      stroke={theme.palette.background.paper}
                      strokeWidth={1}
                    />
                  ))}
                </Pie>
                <Tooltip 
                  formatter={(value, name) => {
                    return [`${value} (${((value / totalValue) * 100).toFixed(1)}%)`, name];
                  }}
                  contentStyle={{ 
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: 8,
                    padding: '8px 12px',
                    boxShadow: theme.shadows[3],
                    border: 'none'
                  }}
                />
              </PieChart>
            </ResponsiveContainer>
          </Box>

          <Box sx={{ 
            mt: 2, 
            display: 'flex', 
            flexWrap: 'wrap', 
            justifyContent: 'center',
            gap: 1
          }}>
            {filteredData.map((item, index) => (
              <Chip 
                key={index}
                label={`${item.name}: ${item.value}`}
                size="small"
                sx={{ 
                  backgroundColor: item.color || COLORS[index % COLORS.length],
                  color: 'white',
                  fontWeight: activeIndex === index ? 'bold' : 'normal',
                  transform: activeIndex === index ? 'scale(1.05)' : 'scale(1)',
                  transition: 'all 0.2s ease',
                  mb: 0.5,
                  '& .MuiChip-label': { px: 1 }
                }}
              />
            ))}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CategoryPieChart;
