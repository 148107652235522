import React from 'react';
import { 
  List, ListItemButton, ListItemText, Drawer, ListItem, Collapse, 
  Typography, Chip, Box, Avatar, Divider, useTheme
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import routesMenu from './routesMenu';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import HotelIcon from '@mui/icons-material/Hotel';
import CategoryIcon from '@mui/icons-material/Category';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DashboardIcon from '@mui/icons-material/Dashboard';

export const DrawerMenu = ({ open, onClose, containerWidth = 280 }) => {
  const location = useLocation();
  const theme = useTheme();

  const [expandPackages, setExpandPackages] = React.useState(false);
  const [expandProductTab, setExpandProductTab] = React.useState(false);

  React.useEffect(() => {
    // Auto-expand sections based on current path
    if (location.pathname.includes('pack')) setExpandPackages(true);
    if (location.pathname.includes('produto') || location.pathname.includes('categor')) setExpandProductTab(true);
  }, [location.pathname]);

  return (
    <Drawer 
      open={open} 
      onClose={onClose} 
      PaperProps={{
        sx: {
          width: containerWidth,
          bgcolor: 'background.default',
          boxShadow: 3
        }
      }}
    >
      {/* Header with logo */}
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        p: 2, 
        bgcolor: theme.palette.primary.main,
        color: 'white'
      }}>
        <Avatar sx={{ mr: 2, bgcolor: 'white' }}>
          <DashboardIcon color="primary" />
        </Avatar>
        <Typography variant="h6" fontWeight="bold">Loumar CMS</Typography>
      </Box>
      
      <Box sx={{ overflow: 'auto', height: '100%' }}>
        <List sx={{ py: 1 }}>
          {/* Main navigation items */}
          {routesMenu.filter(item => !item.path.includes('pack')).map((route, index) => (
            <MenuItem 
              to={route.path} 
              key={`${route.name}-${index}`} 
              selected={route.path === location.pathname} 
              icon={route.icon} 
              isNew={route.isNew}
            >
              {route.name}
            </MenuItem>
          ))}

          <Divider sx={{ my: 1.5 }} />
          
          {/* Packages section */}
          <SectionHeader 
            title="Pacotes" 
            expanded={expandPackages} 
            onClick={() => setExpandPackages(!expandPackages)} 
          />
          <Collapse in={expandPackages} timeout={300}>
            <List component="div" disablePadding>
              {routesMenu.filter(item => item.path.includes('pack')).map((route, index) => (
                <MenuItem 
                  to={route.path} 
                  key={`${route.name}-${index}`} 
                  selected={route.path === location.pathname} 
                  icon={route.icon}
                  nested
                >
                  {route.name}
                </MenuItem>
              ))}
            </List>
          </Collapse>
          
          {/* Products section */}
          <SectionHeader 
            title="Produtos" 
            expanded={expandProductTab} 
            onClick={() => setExpandProductTab(!expandProductTab)} 
          />
          <Collapse in={expandProductTab} timeout={300}>
            <List component="div" disablePadding>
              <MenuItem to='/produtos' selected={location.pathname === '/produtos'} icon={<ShoppingCartIcon />} nested>
                Produtos
              </MenuItem>
              <MenuItem to='/categories' selected={location.pathname === '/categories'} icon={<CategoryIcon />} nested>
                Categorias
              </MenuItem>
            </List>
          </Collapse>
          
          <Divider sx={{ my: 1.5 }} />
          
          {/* Other standalone items */}
          <MenuItem to="/hotels" selected={location.pathname === '/hotels'} icon={<HotelIcon />}>
            Hoteis
          </MenuItem>
          <MenuItem to="/orchestrator" selected={location.pathname === '/orchestrator'} icon={<AutoFixHighIcon />}>
            Orquestrador
          </MenuItem>
        </List>
      </Box>
    </Drawer>
  );
};

// Section header component
const SectionHeader = ({ title, expanded, onClick }) => {
  return (
    <ListItem 
      onClick={onClick}
      sx={{
        cursor: 'pointer',
        py: 1,
        '&:hover': {
          bgcolor: 'action.hover',
          borderRadius: 1,
        },
      }}
    >
      <ListItemText 
        primary={title} 
        primaryTypographyProps={{ 
          fontWeight: 700,
          variant: 'subtitle2',
          color: 'text.primary',
          textTransform: 'uppercase',
          fontSize: 13
        }} 
      />
      {expanded ? <ExpandLessIcon color="action" /> : <ExpandMoreIcon color="action" />}
    </ListItem>
  );
};

// Menu item component
const MenuItem = ({ children, to, selected, icon, isNew, nested = false }) => {
  const theme = useTheme();
  
  return (
    <Link to={to} style={{ textDecoration: 'none' }}>
      <ListItemButton
        selected={selected}
        sx={{
          pl: nested ? 4 : 2,
          py: 1,
          mb: 0.5,
          borderRadius: 1,
          '&.Mui-selected': {
            bgcolor: `${theme.palette.primary.main}15`,
            color: 'primary.main',
            '&:hover': {
              bgcolor: `${theme.palette.primary.main}25`,
            },
            '&::before': {
              content: '""',
              position: 'absolute',
              left: 0,
              top: '25%',
              height: '50%',
              width: 4,
              bgcolor: 'primary.main',
              borderTopRightRadius: 4,
              borderBottomRightRadius: 4,
            }
          },
          '&:hover': {
            bgcolor: 'action.hover',
          },
        }}
      >
        {icon && (
          <Box 
            sx={{ 
              mr: 2, 
              color: selected ? 'primary.main' : 'text.secondary',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {icon}
          </Box>
        )}
        <ListItemText 
          primary={
            <Typography 
              variant="body2" 
              fontWeight={selected ? 600 : 400}
              color={selected ? 'primary.main' : 'text.primary'}
            >
              {children}
            </Typography>
          } 
        />
        {isNew && (
          <Chip 
            label="Novo" 
            color="primary" 
            size="small" 
            variant="outlined"
            sx={{ height: 20, fontSize: 10 }}
          />
        )}
      </ListItemButton>
    </Link>
  );
};