import { Box, Button, Container, FormControlLabel, InputLabel, MenuItem, Select, Switch, Tab, Tabs, TextField, Typography, Alert, Snackbar } from "@mui/material";
import { useEffect, useReducer, useState } from "react";
import { getCategories } from "../../services/categoryService";
import { addPromotional } from "../../services/promotionalService";
import { useNavigate } from "react-router-dom";
import BannerItem from "../../components/promotional/BannerItem";
import ObservationsList from "../../components/promotional/ObservationsList";

export const NewPromotional = () => {
    const navigate = useNavigate();
    const [alert, setAlert] = useState({ open: false, message: '', severity: 'info' });
    const [loading, setLoading] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const [categories, setCategories] = useState([]);
    
    // Use the same reducer pattern as in EditPromotional
    const [formData, dispatchFormData] = useReducer((state, action) => {
      switch (action.type) {
        case 'SET_FIELD':
          return { ...state, [action.field]: action.value };
        case 'SET_MULTIPLE_FIELDS':
          return { ...state, ...action.fields };
        case 'UPDATE_OBSERVATION':
          const newObservations = [...state.observations];
          newObservations[action.index] = action.value;
          return { ...state, observations: newObservations };
        case 'ADD_OBSERVATION':
          return { ...state, observations: [...state.observations, ''] };
        case 'REMOVE_OBSERVATION':
          return { 
            ...state, 
            observations: state.observations.filter((_, i) => i !== action.index) 
          };
        case 'UPDATE_BANNER':
          const updatedBanners = state.banners.map((banner, i) => 
            i === action.index ? { ...banner, [action.field]: action.value } : banner
          );
          return { ...state, banners: updatedBanners };
        case 'ADD_BANNER':
          return { 
            ...state, 
            banners: [...state.banners, { mobileImage: '', desktopImage: '', isActive: true, description: '' }] 
          };
        case 'REMOVE_BANNER':
          return {
            ...state,
            banners: state.banners.filter((_, i) => i !== action.index)
          };
        default:
          return state;
      }
    }, {
      name: '',
      selectedCategories: [],
      promotionStartDate: '',
      promotionEndDate: '',
      validationStartDate: '',
      validationEndDate: '',
      maxDiscount: '',
      isActive: true,
      observations: [''],
      banners: [],
    });

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                setLoading(true);
                const data = await getCategories();
                setCategories(data);
            } catch (error) {
                console.error('Erro ao carregar categorias:', error);
                setAlert({
                    open: true,
                    message: 'Erro ao carregar categorias. Por favor, tente novamente.',
                    severity: 'error'
                });
            } finally {
                setLoading(false);
            }
        };
        fetchCategories();
    }, []);

    const createISODate = (dateStr) => {
        if (!dateStr) return null;
        const [year, month, day] = dateStr.split('-');
        return `${year}-${month}-${day}T12:00:00.000Z`;
    };

    // Event handlers
    const handleMaxDiscountChange = (event) => {
        const value = event.target.value;
        const numericValue = value.replace(/[^0-9]/g, '');
        dispatchFormData({ 
            type: 'SET_FIELD', 
            field: 'maxDiscount',
            value: numericValue ? `${numericValue}%` : '' 
        });
    };

    const handleUploadDesktop = (index, data) => {
        const { url } = data;
        dispatchFormData({ 
            type: 'UPDATE_BANNER', 
            index, 
            field: 'desktopImage', 
            value: url 
        });
    };

    const handleUploadMobile = (index, data) => {
        const { url } = data;
        dispatchFormData({ 
            type: 'UPDATE_BANNER', 
            index, 
            field: 'mobileImage', 
            value: url 
        });
    };

    const handleSubmit = async (event) => {
        if (event) event.preventDefault();
        setLoading(true);
        
        try {
            const data = {
                name: formData.name,
                observations: formData.observations.filter(obs => obs.trim() !== ''),
                categories: formData.selectedCategories,
                banners: formData.banners,
                promotional: {
                    promotionStartDate: createISODate(formData.promotionStartDate),
                    promotionEndDate: createISODate(formData.promotionEndDate),
                    validationStartDate: createISODate(formData.validationStartDate),
                    validationEndDate: createISODate(formData.validationEndDate),
                    maxDiscount: parseInt(formData.maxDiscount?.replace('%', '') || 0, 10)
                },
                isActive: formData.isActive,
            };

            await addPromotional(data);
            setAlert({
                open: true,
                message: 'Página promocional criada com sucesso!',
                severity: 'success'
            });
            
            // Redirect after a short delay to show success message
            setTimeout(() => navigate('/promotionalpage'), 1500);
        } catch (error) {
            console.error('Erro ao criar página promocional:', error);
            setAlert({
                open: true,
                message: 'Erro ao criar página promocional. Por favor, tente novamente.',
                severity: 'error'
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container maxWidth="md">
            <Typography variant="h5" my={3}>Adicionar nova página promocional</Typography>
            <Tabs value={tabIndex} onChange={(_, newValue) => setTabIndex(newValue)}>
                <Tab label="Detalhes" />
                <Tab label="Observações" />
                <Tab label="Banners" />
            </Tabs>
            
            <Snackbar 
                open={alert.open} 
                autoHideDuration={6000} 
                onClose={() => setAlert(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert 
                    onClose={() => setAlert(prev => ({ ...prev, open: false }))} 
                    severity={alert.severity} 
                    sx={{ width: '100%' }}
                >
                    {alert.message}
                </Alert>
            </Snackbar>
            
            {tabIndex === 0 && (
                <Box component="form" onSubmit={handleSubmit} sx={{ padding: 4, display: "flex", flexDirection: "column", gap: 4 }}>
                    <TextField 
                        label="Nome da página promocional"
                        required
                        value={formData.name}
                        onChange={(e) => dispatchFormData({ type: 'SET_FIELD', field: 'name', value: e.target.value })}
                    />
                    
                    <FormControlLabel
                        control={
                            <Switch 
                                checked={formData.isActive}
                                onChange={(e) => dispatchFormData({ type: 'SET_FIELD', field: 'isActive', value: e.target.checked })}
                                name="isActive"
                                color="primary"
                            />
                        }
                        label={formData.isActive ? "Ativa" : "Inativa"}
                    />
                    
                    <TextField 
                        label="Desconto Máximo"
                        required
                        value={formData.maxDiscount}
                        onChange={handleMaxDiscountChange}
                        inputProps={{ inputMode: 'numeric', maxLength: 3 }}
                    />
                    
                    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 3 }}>
                        <TextField
                            fullWidth
                            label="Data de Início da Promoção"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            required
                            value={formData.promotionStartDate}
                            onChange={(e) => dispatchFormData({ 
                                type: 'SET_FIELD', 
                                field: 'promotionStartDate', 
                                value: e.target.value 
                            })}
                        />
                        
                        <TextField
                            fullWidth
                            label="Data de Fim da Promoção"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            required
                            value={formData.promotionEndDate}
                            onChange={(e) => dispatchFormData({ 
                                type: 'SET_FIELD', 
                                field: 'promotionEndDate', 
                                value: e.target.value 
                            })}
                        />
                    </Box>
                    
                    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 3 }}>
                        <TextField
                            fullWidth
                            label="Data de Início da Validação"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            required
                            value={formData.validationStartDate}
                            onChange={(e) => dispatchFormData({ 
                                type: 'SET_FIELD', 
                                field: 'validationStartDate', 
                                value: e.target.value 
                            })}
                        />
                        
                        <TextField
                            fullWidth
                            label="Data de Fim da Validação"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            required
                            value={formData.validationEndDate}
                            onChange={(e) => dispatchFormData({ 
                                type: 'SET_FIELD', 
                                field: 'validationEndDate', 
                                value: e.target.value 
                            })}
                        />
                    </Box>
                    
                    <Box>
                        <InputLabel>Categorias</InputLabel>
                        <Select
                            fullWidth
                            multiple
                            value={formData.selectedCategories}
                            onChange={(e) => dispatchFormData({ 
                                type: 'SET_FIELD', 
                                field: 'selectedCategories', 
                                value: e.target.value 
                            })}
                            label="Categorias"
                            renderValue={(selected) => selected.map(id => {
                                const category = categories.find(cat => cat._id === id);
                                return category ? category.name : '';
                            }).join(', ')}
                        >
                            {categories.map((category) => (
                                <MenuItem key={category._id} value={category._id}>
                                    {category.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                    
                    <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                        <Button 
                            variant="contained" 
                            type="submit" 
                            disabled={loading}
                            sx={{ flexGrow: 1 }}
                        >
                            {loading ? 'Criando...' : 'Criar página promocional'}
                        </Button>
                        <Button 
                            variant="outlined" 
                            onClick={() => navigate('/promotionalpage')}
                        >
                            Voltar
                        </Button>
                    </Box>
                </Box>
            )}
            
            {tabIndex === 1 && (
                <Box sx={{ padding: 4, display: "flex", flexDirection: "column", gap: 4 }}>
                    <ObservationsList 
                        observations={formData.observations}
                        onAddObservation={() => dispatchFormData({ type: 'ADD_OBSERVATION' })}
                        onRemoveObservation={(index) => dispatchFormData({ type: 'REMOVE_OBSERVATION', index })}
                        onObservationChange={(index, value) => dispatchFormData({ 
                            type: 'UPDATE_OBSERVATION', 
                            index, 
                            value 
                        })}
                    />
                    
                    <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                        <Button 
                            variant="contained" 
                            onClick={handleSubmit} 
                            disabled={loading}
                            sx={{ flexGrow: 1 }}
                        >
                            {loading ? 'Criando...' : 'Criar página promocional'}
                        </Button>
                        <Button 
                            variant="outlined" 
                            onClick={() => navigate('/promotionalpage')}
                        >
                            Voltar
                        </Button>
                    </Box>
                </Box>
            )}
            
            {tabIndex === 2 && (
                <Box sx={{ padding: 4, display: "flex", flexDirection: "column", gap: 4 }}>
                    {formData.banners.map((banner, index) => (
                        <BannerItem
                            key={index}
                            banner={banner}
                            index={index}
                            onBannerChange={(index, field, value) => dispatchFormData({ 
                                type: 'UPDATE_BANNER', 
                                index, 
                                field, 
                                value 
                            })}
                            onBannerIsActiveChange={(index, e) => dispatchFormData({ 
                                type: 'UPDATE_BANNER', 
                                index, 
                                field: 'isActive', 
                                value: e.target.checked 
                            })}
                            onUploadDesktop={handleUploadDesktop}
                            onUploadMobile={handleUploadMobile}
                            onRemoveBanner={(index) => dispatchFormData({ type: 'REMOVE_BANNER', index })}
                        />
                    ))}
                    
                    <Button 
                        variant="contained" 
                        onClick={() => dispatchFormData({ type: 'ADD_BANNER' })}
                        startIcon={<Typography>+</Typography>}
                    >
                        Adicionar Banner
                    </Button>
                    
                    <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                        <Button 
                            variant="contained" 
                            onClick={handleSubmit} 
                            disabled={loading}
                            sx={{ flexGrow: 1 }}
                        >
                            {loading ? 'Criando...' : 'Criar página promocional'}
                        </Button>
                        <Button 
                            variant="outlined" 
                            onClick={() => navigate('/promotionalpage')}
                        >
                            Voltar
                        </Button>
                    </Box>
                </Box>
            )}
        </Container>
    );
};