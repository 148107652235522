import React, { useEffect, useState } from 'react';
import { Box, Button, Container, MenuItem, FormControl, InputLabel, Select, Grid, Card, CardMedia, CardContent, Typography, IconButton } from '@mui/material';
import { Text } from '../../components/core';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { deletePromotional, getPromotionals } from '../../services/promotionalService';
import { format } from 'date-fns';
import { getCategories } from '../../services/categoryService';

const PromotionalPages = () => {

  const [promotionalPages, setPromotionalPages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');

  useEffect(() => {
    const fetchPromotionals = async () => {
      const data = await getPromotionals();
      setPromotionalPages(data);
    };

    const fetchCategories = async () => {
      const data = await getCategories();
      setCategories(data);
    };

    fetchPromotionals();
    fetchCategories();
  }, []);

  const handleEdit = (id) => {
    window.location.href = `/promotionalpage/edit/${id}`;
  }

  const handleDelete = async (id) => {
      await deletePromotional(id).then(() => {
        alert('Página Promocional deletada com sucesso!');
      }).catch((error) => {
        alert('Erro ao deletar a Página Promocional: ' + error);
      });
      setPromotionalPages(promotionalPages?.filter(page => page._id !== id));

  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const filteredPages = selectedCategory
    ? (promotionalPages || []).filter(page => page.categories.includes(selectedCategory))
    : (promotionalPages || []);

  return (
    <Container maxWidth="xl">
      <Text variant="h5">Páginas Promocionais</Text>
      <Box>
        <Box sx={{ padding: 2, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <FormControl sx={{ minWidth: 220 }} size="small">
            <InputLabel>Categoria</InputLabel>
            <Select
              label="Categoria"
              color="primary"
              value={selectedCategory}
              onChange={handleCategoryChange}
            >
              {categories.map((category) => (
                <MenuItem key={category._id} value={category._id}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button variant="contained" color="primary" href='/promotionalpage/new'>
            Novo
          </Button>
        </Box>
        <Box sx={{ height: "100%", marginTop: 4 }}>
          <Grid container justifyContent="center">
          {Array.isArray(filteredPages) && filteredPages.length === 0 ? (
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center', 
              justifyContent: 'center',
              padding: 5,
              textAlign: 'center',
              width: '100%',
              height: '60vh'
            }}>
              <Typography variant="h6" sx={{ mb: 1 }}>Nenhuma página promocional encontrada</Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                {selectedCategory ? 'Não existem páginas promocionais nesta categoria.' : 'Ainda não foram criadas páginas promocionais.'}
              </Typography>
              <Button variant="contained" color="primary" href='/promotionalpage/new'>
                Criar Página Promocional
              </Button>
            </Box>
          ) : Array.isArray(filteredPages) ? filteredPages.map((item) => (
              <Card 
                key={item._id} 
                sx={{ 
                  maxWidth: 325, 
                  width: '100%', 
                  margin: 2,
                  boxShadow: item.isActive 
                    ? '0 4px 10px rgba(76, 175, 80, 0.25)' 
                    : '0 2px 5px rgba(0, 0, 0, 0.1)',
                  position: 'relative',
                  transition: 'transform 0.2s, box-shadow 0.2s',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: item.isActive 
                      ? '0 6px 14px rgba(76, 175, 80, 0.3)' 
                      : '0 5px 10px rgba(0, 0, 0, 0.15)',
                  }
                }}
              >
                <Box 
                  sx={{
                    position: 'absolute',
                    top: 10,
                    right: 10,
                    width: 10,
                    height: 10,
                    borderRadius: '50%',
                    backgroundColor: item.isActive ? '#4caf50' : '#f44336',
                    boxShadow: '0 0 4px rgba(0,0,0,0.2)'
                  }}
                />
                <CardMedia
                  component="img"
                  sx={{ 
                    height: 270, 
                    objectFit: 'cover', 
                    marginBottom: 2,
                    opacity: item.isActive ? 1 : 0.7
                  }}
                  image={item?.banners?.[0]?.mobileImage || ''}
                  alt="Imagem da Página Promocional"
                />
                <CardContent>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography 
                      gutterBottom 
                      variant="h6" 
                      component="div"
                      sx={{ color: item.isActive ? 'text.primary' : 'text.secondary' }}
                    >
                      {item?.name || ''}
                    </Typography>
                    <Box>
                      <IconButton onClick={() => handleEdit(item._id)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDelete(item._id)}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </div>
                  <Typography variant='body2' color='text.secondary' mt={1}>
                    Início da Promoção: {item?.promotional?.promotionStartDate ? format(new Date(item.promotional.promotionStartDate), 'dd/MM/yyyy') : 'N/A'}<br />
                    Fim da Promoção: {item?.promotional?.promotionEndDate ? format(new Date(item.promotional.promotionEndDate), 'dd/MM/yyyy') : 'N/A'}<br />
                  </Typography>
                  <Typography 
                    mt={1} 
                    variant="body2" 
                    sx={{ 
                      color: item.isActive ? '#4caf50' : '#f44336',
                      fontWeight: 'bold'
                    }}
                  >
                    {item?.isActive ? 'Promoção Ativa' : 'Promoção Inativa'}<br />
                  </Typography>
                </CardContent>
              </Card>
            )) : (
              <Typography variant="body1">Carregando páginas promocionais...</Typography>
            )}
          </Grid>
        </Box>
      </Box>
    </Container>
  )
};

export default PromotionalPages;