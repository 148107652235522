import React, { useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";

export const DropdownMenu = ({ options, style }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSelect = (option) => {
    option.action();
    handleCloseMenu();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={style}>
      <IconButton onClick={handleOpenMenu}>
        <MoreHoriz />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {options.map((option, index) => (
          <MenuItem key={index} onClick={() => handleSelect(option)}>
            {option?.icon}
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
