import { Box, Button, FormControlLabel, IconButton, Switch, TextField } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { UploadBox } from "../patterns";

/**
 * Component for managing a single banner in promotional pages
 * 
 * @param {Object} props - Component props
 * @param {Object} props.banner - Banner data object
 * @param {number} props.index - Banner index in the array
 * @param {Function} props.onBannerChange - Callback for changing banner fields
 * @param {Function} props.onBannerIsActiveChange - Callback for changing banner active state
 * @param {Function} props.onUploadDesktop - Callback for uploading desktop image
 * @param {Function} props.onUploadMobile - Callback for uploading mobile image
 * @param {Function} props.onRemoveBanner - Callback for removing banner
 */
const BannerItem = ({ 
  banner, 
  index, 
  onBannerChange, 
  onBannerIsActiveChange, 
  onUploadDesktop, 
  onUploadMobile, 
  onRemoveBanner 
}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, my: 2, p: 2, border: '1px solid #e0e0e0', borderRadius: 1 }}>
      <FormControlLabel
        control={
          <Switch
            checked={banner.isActive}
            onChange={(e) => onBannerIsActiveChange(index, e)}
            name={`isActive-${index}`}
            color="primary"
          />
        }
        label={banner.isActive ? "Ativo" : "Inativo"}
      />
      
      {/* Desktop image upload */}
      {!banner.desktopImage ? (
        <UploadBox
          uploadText="Upload banner desktop"
          data={banner}
          handleUpload={(data) => onUploadDesktop(index, data)}
          sx={{ width: 'auto', height: '80px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 2 }}
        />
      ) : (
        <Box sx={{ position: 'relative', width: 'auto', height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 2 }}>
          <IconButton
            size="small"
            onClick={() => onBannerChange(index, 'desktopImage', '')}
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              backgroundColor: '#e1e1e1',
              '&:hover': { backgroundColor: '#d1d1d1' }
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <img
            src={banner.desktopImage}
            alt="Banner Desktop"
            style={{ width: 200, height: 90, objectFit: "contain", borderRadius: '13px' }}
          />
        </Box>
      )}
      
      {/* Mobile image upload */}
      {!banner.mobileImage ? (
        <UploadBox
          uploadText="Upload banner mobile"
          data={banner}
          handleUpload={(data) => onUploadMobile(index, data)}
          sx={{ width: 'auto', height: '80px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 2 }}
        />
      ) : (
        <Box sx={{ position: 'relative', width: 'auto', height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 2 }}>
          <IconButton
            size="small"
            onClick={() => onBannerChange(index, 'mobileImage', '')}
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              backgroundColor: '#e1e1e1',
              '&:hover': { backgroundColor: '#d1d1d1' }
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <img
            src={banner.mobileImage}
            alt="Banner Mobile"
            style={{ width: 200, height: 90, objectFit: "cover", borderRadius: '13px' }}
          />
        </Box>
      )}
      
      {/* Banner description */}
      <TextField
        fullWidth
        label="Descrição"
        value={banner.description || ''}
        onChange={(e) => onBannerChange(index, 'description', e.target.value)}
      />
      
      {/* Action buttons */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
        <Button 
          variant="outlined" 
          color="error" 
          startIcon={<DeleteIcon />}
          onClick={() => onRemoveBanner(index)}
        >
          Remover Banner
        </Button>
      </Box>
    </Box>
  );
};

export default BannerItem;
