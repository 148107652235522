import { Box, Button, CircularProgress, Divider, LinearProgress, Paper, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import axios from 'axios'
import ConfirmationModal from './ConfirmationModal'

async function getAliasList() {
    const data = axios.get('https://api.vercel.com/v4/aliases/www.loumarturismo.com.br?teamId=team_b7A5rYE05eAFvBdOg0djrTgX&projectId=prj_oMLFsw1Qs3LZ100o6JgnjGf3LM7Z', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_VERCEL_API_TOKEN}`
        }
    })
        .then(response => {
            return response.data
        })
        .catch(error => {
            console.error(error)
        })
    const aliasList = await data
    return aliasList
}

async function getDeploymentData() {
    const data = axios.get('https://api.vercel.com/v6/deployments?teamId=team_b7A5rYE05eAFvBdOg0djrTgX&projectId=prj_oMLFsw1Qs3LZ100o6JgnjGf3LM7Z&target=production', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_VERCEL_API_TOKEN}`
        }
    })
        .then(response => {
            return response.data
        })
        .catch(error => {
            console.error(error)
        })
    const deploymentData = await data
    return deploymentData

}


export default function Orchestrator() {
    const [aliasList, setAliasList] = React.useState([])
    const [deploymentData, setDeploymentData] = React.useState([])
    const [openModal, setOpenModal] = React.useState(false)
    const [buttonDisabled, setButtonDisabled] = React.useState(true)
    useEffect(() => {
        async function getData() {
            await getAliasList().then(response => {
                setAliasList(response)
            })
        }
        getData()
    }, [])
    useEffect(() => {
        async function getData() {
            await getDeploymentData().then(response => {
                setDeploymentData(response)
            })
        }
        getData()
    }, [])
    useEffect(() => {
        if (deploymentData.deployments) {
            setButtonDisabled(deploymentData.deployments[0].uid !== aliasList.deployment?.id)
        }
    }, [deploymentData, aliasList, setButtonDisabled])
    return (
        <Box
            display={'flex'}
            justifyContent={'center'}
            flexDirection={'column'}
        // gap={3}
        >
            <Paper elevation={3}>

                <Box
                    display={'flex'}
                    width={600}
                    height={350}
                    justifyContent={'center'}
                    alignItems={'center'}
                    flexDirection={'column'}
                >
                    {aliasList ?
                        <>
                            <Table
                                size='small'
                            >
                                <TableBody>
                                    <TableRow>
                                        <TableCell >
                                            <Typography variant='h6'>Alias</Typography>
                                        </TableCell>
                                        <TableCell>{aliasList.alias}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell >
                                            <Typography variant='h6'>Deployment ID</Typography>
                                        </TableCell>
                                        <TableCell>{aliasList.deployment?.id}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell >
                                            <Typography variant='h6'>Deployment URL</Typography>
                                        </TableCell>
                                        <TableCell>{aliasList.deployment?.url}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell >
                                            <Typography variant='h6'>Atualizado Em</Typography>
                                        </TableCell>
                                        <TableCell>{new Date(aliasList.updatedAt).toLocaleString('pt-BR')}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <Button
                                disabled={buttonDisabled}
                                variant='contained'
                                sx={{
                                    marginTop: 4
                                }}
                                onClick={() => setOpenModal(true)}
                            >
                                Atualizar Deploy
                            </Button>
                            <ConfirmationModal openModal={openModal} setOpenModal={setOpenModal} setButtonDisabled={setButtonDisabled} />
                        </>
                        :
                        <CircularProgress />
                    }
                </Box>
            </Paper>
            {deploymentData?.deployments && deploymentData.deployments[0]?.uid !== aliasList.deployment?.id &&
                <>
                    <Divider orientation='vertical' variant='fullWidth' flexItem sx={{ height: 100, alignSelf: 'center', border: 'dashed 3px' }} />
                    <Paper elevation={3}>
                        <Box
                            display={'flex'}
                            width={600}
                            height={350}
                            justifyContent={'center'}
                            alignItems={'center'}
                            flexDirection={'column'}
                        >
                            <>
                                <Table
                                    size='small'
                                >
                                    <TableBody>
                                        <TableRow>
                                            <TableCell >
                                                <Typography variant='h6'>Deployment ID</Typography>
                                            </TableCell>
                                            <TableCell>{deploymentData.deployments[0].uid}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell >
                                                <Typography variant='h6'>Deployment URL</Typography>
                                            </TableCell>
                                            <TableCell>{deploymentData.deployments[0].url}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell >
                                                <Typography variant='h6'>Criado em</Typography>
                                            </TableCell>
                                            <TableCell>{new Date(deploymentData.deployments[0].created).toLocaleString('pt-BR')}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell >
                                                <Typography variant='h6'>Situação</Typography>
                                            </TableCell>
                                            <TableCell>{deploymentData.deployments[0].state} <LinearProgress /></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </>

                        </Box>
                    </Paper>
                </>
            }
        </Box>
    )
}
