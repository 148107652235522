import { 
  Box, Button, Container, FormControlLabel, 
  InputLabel, MenuItem, Select, Switch, Tab, Tabs, 
  TextField, Typography, Alert, Snackbar, 
  CircularProgress,
  Skeleton
} from "@mui/material";
import { useEffect, useReducer, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getCategories } from "../../services/categoryService";
import { getPromotionalById, updatePromotional } from "../../services/promotionalService";
import BannerItem from "../../components/promotional/BannerItem";
import ObservationsList from "../../components/promotional/ObservationsList";

// Main component with improved organization
export const EditPromotional = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [alert, setAlert] = useState({ open: false, message: '', severity: 'info' });
    const [loading, setLoading] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    
    // Group related form data using reducer pattern
    const [formData, dispatchFormData] = useReducer((state, action) => {
      switch (action.type) {
        case 'SET_FIELD':
          return { ...state, [action.field]: action.value };
        case 'SET_MULTIPLE_FIELDS':
          return { ...state, ...action.fields };
        case 'UPDATE_OBSERVATION':
          const newObservations = [...state.observations];
          newObservations[action.index] = action.value;
          return { ...state, observations: newObservations };
        case 'ADD_OBSERVATION':
          return { ...state, observations: [...state.observations, ''] };
        case 'REMOVE_OBSERVATION':
          return { 
            ...state, 
            observations: state.observations.filter((_, i) => i !== action.index) 
          };
        case 'UPDATE_BANNER':
          const updatedBanners = state.banners.map((banner, i) => 
            i === action.index ? { ...banner, [action.field]: action.value } : banner
          );
          return { ...state, banners: updatedBanners };
        case 'ADD_BANNER':
          return { 
            ...state, 
            banners: [...state.banners, { mobileImage: '', desktopImage: '', isActive: true, description: '' }] 
          };
        case 'REMOVE_BANNER':
          return {
            ...state,
            banners: state.banners.filter((_, i) => i !== action.index)
          };
        case 'LOAD_DATA':
          return { ...action.data };
        default:
          return state;
      }
    }, {
      name: '',
      selectedCategories: [],
      promotionStartDate: '',
      promotionEndDate: '',
      validationStartDate: '',
      validationEndDate: '',
      maxDiscount: '',
      isActive: true,
      observations: [''],
      banners: [],
    });

    const [categories, setCategories] = useState([]);

    // Format date from ISO to YYYY-MM-DD
    const formatDate = (dateString) => {
      if (!dateString) return '';
      const date = new Date(dateString);
      return date.toISOString().split('T')[0];
    };

    // Create ISO date from YYYY-MM-DD
    const createISODate = (dateStr) => {
      if (!dateStr) return null;
      const [year, month, day] = dateStr.split('-');
      return `${year}-${month}-${day}T12:00:00.000Z`;
    };

    useEffect(() => {
      const fetchData = async () => {
        setLoading(true);
        try {
          // Fetch categories and promotional data in parallel
          const [categoriesData, promotionalData] = await Promise.all([
            getCategories(),
            getPromotionalById(id)
          ]);
          
          setCategories(categoriesData);
          
          // Format the data for the form
          dispatchFormData({
            type: 'LOAD_DATA',
            data: {
              name: promotionalData.name || '',
              selectedCategories: promotionalData.categories || [],
              promotionStartDate: formatDate(promotionalData.promotional.promotionStartDate),
              promotionEndDate: formatDate(promotionalData.promotional.promotionEndDate),
              validationStartDate: formatDate(promotionalData.promotional.validationStartDate),
              validationEndDate: formatDate(promotionalData.promotional.validationEndDate),
              maxDiscount: promotionalData.promotional.maxDiscount ? `${promotionalData.promotional.maxDiscount}%` : '',
              isActive: promotionalData.isActive !== undefined ? promotionalData.isActive : true,
              observations: promotionalData.observations?.length ? promotionalData.observations : [''],
              banners: promotionalData.banners || [],
            }
          });
        } catch (error) {
          console.error('Error fetching initial data:', error);
          setAlert({
            open: true,
            message: 'Erro ao carregar os dados. Por favor, tente novamente.',
            severity: 'error'
          });
        } finally {
          setLoading(false);
        }
      };
      
      fetchData();
    }, [id]);

    // Event handlers
    const handleMaxDiscountChange = (event) => {
      const value = event.target.value;
      const numericValue = value.replace(/[^0-9]/g, '');
      dispatchFormData({ 
        type: 'SET_FIELD', 
        field: 'maxDiscount',
        value: numericValue ? `${numericValue}%` : '' 
      });
    };

    const handleUploadDesktop = (index, data) => {
      const { url } = data;
      dispatchFormData({ 
        type: 'UPDATE_BANNER', 
        index, 
        field: 'desktopImage', 
        value: url 
      });
    };

    const handleUploadMobile = (index, data) => {
      const { url } = data;
      dispatchFormData({ 
        type: 'UPDATE_BANNER', 
        index, 
        field: 'mobileImage', 
        value: url 
      });
    };

    const handleSubmit = async (event) => {
      if (event) event.preventDefault();
      setLoading(true);

      try {
        const data = {
          name: formData.name,
          observations: formData.observations.filter(obs => obs.trim() !== ''),
          categories: formData.selectedCategories,
          banners: formData.banners.map(({ _id, ...bannerData }) => bannerData),
          promotional: {
            promotionStartDate: createISODate(formData.promotionStartDate),
            promotionEndDate: createISODate(formData.promotionEndDate),
            validationStartDate: createISODate(formData.validationStartDate),
            validationEndDate: createISODate(formData.validationEndDate),
            maxDiscount: parseInt(formData.maxDiscount?.replace('%', '') || 0, 10)
          },
          isActive: formData.isActive,
        };

        await updatePromotional(id, data);
        setAlert({
          open: true,
          message: 'Página promocional atualizada com sucesso!',
          severity: 'success'
        });
        
        // Redirect after a short delay to show success message
        setTimeout(() => navigate('/promotionalpage'), 1500);
      } catch (error) {
        console.error('Erro ao atualizar página promocional:', error);
        setAlert({
          open: true,
          message: 'Erro ao salvar as alterações. Por favor, tente novamente.',
          severity: 'error'
        });
      } finally {
        setLoading(false);
      }
    };

    // Render loading state
    if (loading && !formData.name) {
        return (
            <Container maxWidth="md">
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', py: 4 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                        <CircularProgress size={40} thickness={4} sx={{ mr: 2 }} />
                        <Typography variant="h5" sx={{ fontWeight: 'medium' }}>
                            Carregando página promocional
                        </Typography>
                    </Box>
                    
                    <Box sx={{ width: '100%', mt: 2 }}>
                        <Skeleton variant="rectangular" width="100%" height={60} sx={{ mb: 2 }} />
                        <Skeleton variant="rectangular" width="70%" height={40} sx={{ mb: 2 }} />
                        
                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 3, my: 3 }}>
                            <Skeleton variant="rectangular" height={56} />
                            <Skeleton variant="rectangular" height={56} />
                        </Box>
                        
                        <Skeleton variant="rectangular" width="100%" height={100} sx={{ mb: 2 }} />
                        <Skeleton variant="rectangular" width="40%" height={36} />
                    </Box>
                </Box>
            </Container>
        );
    }

    return (
      <Container maxWidth="md">
        <Typography variant="h5" my={3}>Editar página promocional</Typography>
        <Tabs value={tabIndex} onChange={(_, newValue) => setTabIndex(newValue)}>
          <Tab label="Detalhes" />
          <Tab label="Observações" />
          <Tab label="Banners" />
        </Tabs>
        
        <Snackbar 
          open={alert.open} 
          autoHideDuration={6000} 
          onClose={() => setAlert(prev => ({ ...prev, open: false }))}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert 
            onClose={() => setAlert(prev => ({ ...prev, open: false }))} 
            severity={alert.severity} 
            sx={{ width: '100%' }}
          >
            {alert.message}
          </Alert>
        </Snackbar>
        
        {tabIndex === 0 && (
          <Box component="form" onSubmit={handleSubmit} sx={{ padding: 4, display: "flex", flexDirection: "column", gap: 4 }}>
            <TextField 
              label="Nome da página promocional"
              required
              value={formData.name}
              onChange={(e) => dispatchFormData({ type: 'SET_FIELD', field: 'name', value: e.target.value })}
            />
            
            <FormControlLabel
              control={
                <Switch 
                  checked={formData.isActive}
                  onChange={(e) => dispatchFormData({ type: 'SET_FIELD', field: 'isActive', value: e.target.checked })}
                  name="isActive"
                  color="primary"
                />
              }
              label={formData.isActive ? "Ativa" : "Inativa"}
            />
            
            <TextField 
              label="Desconto Máximo"
              required
              value={formData.maxDiscount}
              onChange={handleMaxDiscountChange}
              inputProps={{ inputMode: 'numeric', maxLength: 3 }}
            />
            
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 3 }}>
              <TextField
                fullWidth
                label="Data de Início da Promoção"
                type="date"
                InputLabelProps={{ shrink: true }}
                required
                value={formData.promotionStartDate}
                onChange={(e) => dispatchFormData({ 
                  type: 'SET_FIELD', 
                  field: 'promotionStartDate', 
                  value: e.target.value 
                })}
              />
              
              <TextField
                fullWidth
                label="Data de Fim da Promoção"
                type="date"
                InputLabelProps={{ shrink: true }}
                required
                value={formData.promotionEndDate}
                onChange={(e) => dispatchFormData({ 
                  type: 'SET_FIELD', 
                  field: 'promotionEndDate', 
                  value: e.target.value 
                })}
              />
            </Box>
            
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 3 }}>
              <TextField
                fullWidth
                label="Data de Início da Validação"
                type="date"
                InputLabelProps={{ shrink: true }}
                required
                value={formData.validationStartDate}
                onChange={(e) => dispatchFormData({ 
                  type: 'SET_FIELD', 
                  field: 'validationStartDate', 
                  value: e.target.value 
                })}
              />
              
              <TextField
                fullWidth
                label="Data de Fim da Validação"
                type="date"
                InputLabelProps={{ shrink: true }}
                required
                value={formData.validationEndDate}
                onChange={(e) => dispatchFormData({ 
                  type: 'SET_FIELD', 
                  field: 'validationEndDate', 
                  value: e.target.value 
                })}
              />
            </Box>
            
            <Box>
              <InputLabel>Categorias</InputLabel>
              <Select
                fullWidth
                multiple
                value={formData.selectedCategories}
                onChange={(e) => dispatchFormData({ 
                  type: 'SET_FIELD', 
                  field: 'selectedCategories', 
                  value: e.target.value 
                })}
                renderValue={(selected) => selected.map(id => {
                  const category = categories.find(cat => cat._id === id);
                  return category ? category.name : '';
                }).join(', ')}
              >
                {categories.map((category) => (
                  <MenuItem key={category._id} value={category._id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            
            <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
              <Button 
                variant="contained" 
                type="submit" 
                disabled={loading}
                sx={{ flexGrow: 1 }}
              >
                {loading ? 'Salvando...' : 'Salvar alterações'}
              </Button>
              <Button 
                variant="outlined" 
                onClick={() => navigate('/promotionalpage')}
              >
                Voltar
              </Button>
            </Box>
          </Box>
        )}
        
        {tabIndex === 1 && (
          <Box sx={{ padding: 4, display: "flex", flexDirection: "column", gap: 4 }}>
            <ObservationsList 
              observations={formData.observations}
              onAddObservation={() => dispatchFormData({ type: 'ADD_OBSERVATION' })}
              onRemoveObservation={(index) => dispatchFormData({ type: 'REMOVE_OBSERVATION', index })}
              onObservationChange={(index, value) => dispatchFormData({ 
                type: 'UPDATE_OBSERVATION', 
                index, 
                value 
              })}
            />
            
            <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
              <Button 
                variant="contained" 
                onClick={handleSubmit} 
                disabled={loading}
                sx={{ flexGrow: 1 }}
              >
                {loading ? 'Salvando...' : 'Salvar alterações'}
              </Button>
              <Button 
                variant="outlined" 
                onClick={() => navigate('/promotionalpage')}
              >
                Voltar
              </Button>
            </Box>
          </Box>
        )}
        
        {tabIndex === 2 && (
          <Box sx={{ padding: 4, display: "flex", flexDirection: "column", gap: 4 }}>
            {formData.banners.map((banner, index) => (
              <BannerItem
                key={index}
                banner={banner}
                index={index}
                onBannerChange={(index, field, value) => dispatchFormData({ 
                  type: 'UPDATE_BANNER', 
                  index, 
                  field, 
                  value 
                })}
                onBannerIsActiveChange={(index, e) => dispatchFormData({ 
                  type: 'UPDATE_BANNER', 
                  index, 
                  field: 'isActive', 
                  value: e.target.checked 
                })}
                onUploadDesktop={handleUploadDesktop}
                onUploadMobile={handleUploadMobile}
                onRemoveBanner={(index) => dispatchFormData({ type: 'REMOVE_BANNER', index })}
              />
            ))}
            
            <Button 
              variant="contained" 
              onClick={() => dispatchFormData({ type: 'ADD_BANNER' })}
              startIcon={<Typography>+</Typography>}
            >
              Adicionar Banner
            </Button>
            
            <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
              <Button 
                variant="contained" 
                onClick={handleSubmit} 
                disabled={loading}
                sx={{ flexGrow: 1 }}
              >
                {loading ? 'Salvando...' : 'Salvar alterações'}
              </Button>
              <Button 
                variant="outlined" 
                onClick={() => navigate('/promotionalpage')}
              >
                Voltar
              </Button>
            </Box>
          </Box>
        )}
      </Container>
    );
};