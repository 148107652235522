import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Grid, 
  CircularProgress, 
  Typography,
  IconButton
} from '@mui/material';
import { 
  Inventory,
  Category, 
  Hotel, 
  Luggage,
  Refresh,
  TrendingUp
} from '@mui/icons-material';
import { getCategories } from '../../services/categoryService';
import CategoryPieChart from '../../components/Charts/CategoryPieChart';
import StatCard from '../../components/Dashboard/StatCard';
import SalesChart from '../../components/Dashboard/SalesChart';
import RecentActivity from '../../components/Dashboard/RecentActivity';
import { getProdutos } from '../../services/produtosService';


const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [categoryCount, setCategoryCount] = useState(0);
  const [categoryPerformance, setCategoryPerformance] = useState([]);
  const [products, setProducts] = useState([]);

  const fetchCategories = async () => {
    try {
      const categoryList = await getCategories();
      if (categoryList) {
        // Filter out inactive categories
        setCategories(categoryList);
        setCategoryCount(categoryList.length);
        
        // Fetch products to count by category
        const productsResponse = await getProdutos().then(
            response => {
                setProducts(response)
                return response
            }
        ).catch(error => {
            console.error('Error fetching products:', error);
            return { data: [] };
        });


        // Only include active categories in the performance data
        const categoryData = categoryList.map((category) => ({
            name: category.name,
            value: productsResponse.filter(product => product.category_id === category._id && product.isActive ).length || 0,
            color: category.color // Use category color if available
        }));
        setCategoryPerformance(categoryData);
        console.log('new category data is:', categoryPerformance);
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const handleRefresh = () => {
    setLoading(true);
    fetchCategories().finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    // Fetch categories data
    fetchCategories();
    
    // Simulate data loading
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500);
    
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3, width: '100%' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4" component="h1" fontWeight="bold">
          Dashboard
        </Typography>
        <IconButton color="primary" aria-label="refresh" onClick={handleRefresh}>
          <Refresh />
        </IconButton>
      </Box>

      {/* Stats Cards */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
      <Grid item xs={12} sm={6} md={3}>
          <StatCard 
            icon={Inventory}
            title="Produtos ativos" 
            value={categoryPerformance.reduce((acc, category) => acc + category.value, 0).toString()} 
            color="#FF8042"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard 
            icon={TrendingUp}
            title="Produtos promocionais" 
            value={products.filter(product => product.promotional.isActive).length.toString()} 
            color="#0088FE"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard 
            icon={Category}
            title="Categorias totais" 
            value={categoryCount.toString()} 
            color="#00C49F"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          {/* <StatCard 
            icon={Hotel}
            title="Total Hotels" 
            value="85" 
            color="#FFBB28"
          /> */}
        </Grid>
        
      </Grid>

      {/* Charts */}
      <Grid container spacing={3}>

        {/* Category Pie Chart */}
        <Grid item xs={12} lg={4}>
          <CategoryPieChart categoryData={categoryPerformance} />
        </Grid>

        {/* Recent Activity */}
        {/* <Grid item xs={12}>
          <RecentActivity />
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default Dashboard;
