import makeRequest from "./makeRequest";

export const createCategory = async (data) => {
  const { name, thumb, link } = data;
  const response = await makeRequest("/api/category", "POST", { name, thumb, link });
  return response;
};

export const getCategories = async () => {
  const response = await makeRequest("/api/category", "GET");
  return response;
};

export const getCategory = async (id) => {
  const response = await makeRequest(`/api/category/${id}`, "GET");
  return response;
};

export const updateCategory = async ({ id, data }) => {
  const response = await makeRequest(`/api/category/${id}`, "PATCH", data);
  return response;
};

export const deleteCategory = async (id) => {
  const response = await makeRequest(`/api/category/${id}`, "DELETE");
  return response;
};

/**
 * Process products and categories data to create a chart-ready dataset
 * showing the distribution of products across categories
 * @param {Array} categories - Array of category objects
 * @param {Array} products - Array of product objects
 * @returns {Array} - Chart-ready data array with name and value properties
 */
export const getProductsByCategoryData = (categories, products) => {
  if (!categories || !products || categories.length === 0 || products.length === 0) {
    return [];
  }
  
  // Create a map for fast category lookups
  const categoryMap = {};
  categories.forEach(category => {
    categoryMap[category._id] = {
      name: category.name,
      count: 0
    };
  });
  
  // Count products per category
  products.forEach(product => {
    if (categoryMap[product.category_id]) {
      categoryMap[product.category_id].count += 1;
    }
  });
  
  // Transform to format needed for pie chart
  const chartData = Object.values(categoryMap)
    .filter(category => category.count > 0)
    .map(category => ({
      name: category.name,
      value: category.count
    }));
  
  return chartData;
};
