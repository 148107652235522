import api from './api';

export const getPromotionals = async () => {
    const response = await api.get('api/promotionalpage');
    return response.data;
}

export const addPromotional = async (data) => {
    const response = await api.post('api/promotionalpage', data);
    return response;
}

export const getPromotionalById = async (id) => {
    const response = await api.get(`api/promotionalpage/${id}`);
    return response.data;
}

export const updatePromotional = async (id, data) => {
    const response = await api.put(`api/promotionalpage/${id}`, data);
    return response;
}

export const deletePromotional = async (id) => {
    const response = await api.delete(`api/promotionalpage/${id}`);
    return response;
}