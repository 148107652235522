import HomeIcon from '@mui/icons-material/Home';
import WebStoriesIcon from '@mui/icons-material/WebStories';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import ScheduleIcon from '@mui/icons-material/Schedule';
import CategoryIcon from '@mui/icons-material/Category';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import LocalShippingIcon from '@mui/icons-material/LocalShipping'; 
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import LinkIcon from '@mui/icons-material/Link';
import LocalOfferIcon from '@mui/icons-material/LocalOffer'; 
import DashboardIcon from '@mui/icons-material/Dashboard';

const routesMenu = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: <DashboardIcon/>,
    isNew: true,
  },
  {
    path: '/',
    name: 'Pages',
    icon: <HomeIcon />
  },
  {
    path: '/webstories',
    name: 'Stories',
    icon: <WebStoriesIcon />
  },
  {
    path: '/sliders',
    name: 'Sliders',
    icon: <SlideshowIcon />
  },
  {
    path: '/schedule',
    name: 'Programação no Youtube',
    icon: <ScheduleIcon />
  },
  {
    path: '/packageCategories',
    name: 'Categorias de Pacote',
    icon: <CategoryIcon />
  },
  {
    path: '/packageSubcategories',
    name: 'Subcategoria de Pacote',
    icon: <SubdirectoryArrowRightIcon />
  },
  {
    path: '/packages',
    name: 'Pacotes',
    icon: <LocalShippingIcon /> 
  },
  {
    path: '/links',
    name: 'Links úteis',
    icon: <LinkIcon />
  },
  {
    path: '/promotionalpage',
    name: 'Paginas Promocionais',
    icon: <LocalOfferIcon />,
    isNew: true,
  }
].sort((a, b) => a.name.localeCompare(b.name));

export default routesMenu;